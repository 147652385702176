import React, { useMemo } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Select,
  Spacer,
  Text,
  VStack,
  Divider,
  Center,
  Icon,
  useColorModeValue,
  Link as ChakraLink,
} from "@chakra-ui/react";
import Card from "../Card";
import { Goal, useGoals } from "../../data/goalsApi";
import GoalCard from "../goals/GoalCard";
import { AddIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Link, navigate } from "gatsby";

const GoalsTile = () => {
  const [statusFilter, setStatusFilter] = React.useState("COMPLETE");
  const { data: goals, refetch } = useGoals();
  const textColor = useColorModeValue("blue.800", "white");

  const filteredGoals = useMemo(() => {
    return goals?.filter((goal) => {
      if (statusFilter === "ALL") return true;
      return goal.status === statusFilter;
    });
  }, [goals, statusFilter]);

  const pages = useMemo(() => {
    if (!filteredGoals) return [];

    let numPages = filteredGoals.length / 2;
    if (filteredGoals.length % 2 !== 0) numPages++;

    const pages = [];
    for (let i = 0; i < numPages; i++) {
      pages.push(filteredGoals.slice(i * 2, i * 2 + 2));
    }
    return pages;
  }, [filteredGoals]);
  const [currentPage, setCurrentPage] = React.useState(0);

  // @ts-ignore
  return (
    <main aria-label="Goals">
      <Card flex={3} shadow="lg" p={7}>
        <Heading
          size="md"
          as="h2"
          _hover={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/73281/goals");
          }}
          mb={2}
        >
          Goals
        </Heading>
        <Divider mb={4}/>

        <Text mb={2}> How would you like to filter your goals? </Text>

        <Select
          mb={5}
          value={statusFilter}
          onChange={(e) => {
            setStatusFilter(e.target.value);
          }}
          id="Select_Status"
          aria-label="Status Filter"
        >
          <option value="COMPLETE">Complete</option>
          <option value="STARTED">In Progress</option>
          <option value="ALL">Everything</option>
        </Select>

        {pages && pages.length > 0 ? (
          <>
            {pages[currentPage].map((goal: Goal, index) => (
              <Box mt={1} key={"Goal" + index}> 
                <GoalCard
                  goal={goal}
                  onClick={() => {
                    navigate("/73281/goals?initId=" + goal.itemId);
                  }}
                />
              </Box>
            ))}
            {filteredGoals && filteredGoals?.length > 2 && (
              <Flex mt={2}>
                <IconButton
                  icon={<ChevronLeftIcon />}
                  aria-label={"Previous page"}
                  onClick={() => setCurrentPage(currentPage - 1)}
                  isDisabled={currentPage <= 0}
                />
                <Spacer />
                <IconButton
                  icon={<ChevronRightIcon />}
                  aria-label={"Next page"}
                  onClick={() => setCurrentPage(currentPage + 1)}
                  isDisabled={
                    currentPage + 1 >= pages.length ||
                    pages[currentPage + 1].length === 0
                  }
                >
                  More
                </IconButton>
              </Flex>
            )}
          </>
        ) : (
          <>
            <ChakraLink style={{ textDecoration: 'none' }} as={Link} to="/73281/goals" >
              <Box border="1.5px" borderColor={textColor} w='100%' h={"220px"} p={"80px"} mb={4} mt={4} color='white' rounded={"xl"} > 
                  <Center>
                    <VStack>
                      <Icon color={textColor} as={AddIcon} w={7} h={7} mb="10px" />
                      <Button color={textColor} variant={"link"} style={{ textDecoration: 'none' }} fontSize='md' fontWeight='bold'>Add a new goal.</Button>
                    </VStack>
                  </Center>
              </Box>
            </ChakraLink>
            {statusFilter !== "ALL" && goals != null && goals?.length > 0 && (
              <Alert status="info" rounded={"md"}>
                <AlertIcon />
                Try changing the filter to "Everything" to see all your goals.
              </Alert>
            )}
          </>
        )}
      </Card>
    </main>
  );
};

export default GoalsTile;
