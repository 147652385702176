import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  ModuleProgress,
  useAllModuleProgress,
  useModules,
} from "../../data/modulesAPI";
import { useSettings } from "../../data/settingsApi";
import {
  ArrowForwardIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { SettingsContext } from "../../context/settings-context";
import { FaChevronRight } from "react-icons/fa";

const UpNextItem = () => {
  const bg = useColorModeValue("white", "gray.800");
  const modules = useModules();
  const { data: allModulesProgress, loading } = useAllModuleProgress();

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [isMobile] = useMediaQuery("(max-width: 540px)");

  const [hasCompletedOneModule, setHasCompletedOneModule] = useState(false);

  useEffect(() => {
    if (allModulesProgress) {
      setHasCompletedOneModule(
        allModulesProgress.filter((x) => x.completed > 0).length > 0
      );
    }
  }, [allModulesProgress]);

  // const { data: preferences, fetchData } = useSettings();
  const settings = useContext(SettingsContext);

  // useEffect(() => {
  //   settings?.refetch();
  // }, []);

  // COMMENTED PROBLEMATIC CODE OUT - WILL NEED REIMPLEMENTING WHEN PROGRESS API IS REWORKED

  // const allCompleted = useMemo(() => {
  //   if (!modules || modules.length === 0) return false;

  //   let completed = true;
  //   modules.forEach((module) => {
  //     const { data: progress } = useModuleProgress(module.index.toString());
  //     const percentage = progress?.completed / progress?.total;
  //     if (!percentage || percentage < 0.8) completed = false;
  //   });
  //   return completed;
  // }, [modules]);

  const nextTopic = useMemo(() => {
    if (settings?.data?.lastTopicVisited) {
      const nextTopic = modules
        .flatMap((module) => module.topics)
        .find((m) => m.slug === settings?.data?.lastTopicVisited);
      if (nextTopic) return nextTopic;
    }
    return modules[0].topics[0];
  }, [settings]);

  const nextModule = useMemo(() => {
    return modules.find((m) => m.topics.includes(nextTopic));
  }, [nextTopic]);

  useEffect(() => {
    if (modules) setIsLoaded(true);
  }, [modules]);

  // if (allCompleted) {
  //   return (
  //     <Flex
  //       flexDirection={isMobile ? "row" : "column"}
  //       p="5"
  //       bg={bg}
  //       rounded="3xl"
  //     >
  //       <Center>
  //         <Icon as={GiBalloons} boxSize={20} />
  //       </Center>
  //       <Stack ml={isMobile ? 2 : 0} mt={isMobile ? 0 : 2}>
  //         <Heading size="md">Well Done!</Heading>
  //         <Text>
  //           You have finished all of the module content. Feel free to revisit
  //           any topic or explore the site.
  //         </Text>
  //       </Stack>
  //     </Flex>
  //   );
  // }

  return (
    <>
      <Heading mb={2} size="md">
        {loading ? "" : hasCompletedOneModule ? "Continue" : "Begin"}
      </Heading>
      <Divider mb={4} />
      <Stack
        direction="row"
        as={Link}
        to={"/" + nextTopic.slug}
        bg={bg}
        rounded="3xl"
        justifyContent="space-around"
      >
        <Box
          width={isMobile ? "160px" : 14}
          marginRight="3"
          rounded="lg"
          overflow="hidden"
          maxH={150}
          shadow={"lg"}
        >
          <Image
            objectFit="cover"
            srcSet={nextModule?.image.images.fallback.srcSet}
            alt={nextModule?.title}
            h="full"
            border={isMobile ? "1px solid #E2E8F0" : "none"}
            // change width to 100% on mobile
          />
        </Box>
        <Stack>
          <Heading fontSize="md" noOfLines={3}>
            {nextModule?.title ?? "Unknown Module"}
          </Heading>
          <Text size="md" noOfLines={3}>
            {nextTopic.title}
          </Text>
        </Stack>
        <Flex>
          <Box>
            <Icon as={FaChevronRight} opacity={0.5} />
          </Box>
        </Flex>
      </Stack>
    </>
  );
};

export default UpNextItem;
