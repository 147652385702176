import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { Module, ModuleProgress, useModuleProgress } from "../data/modulesAPI";
import ModuleProgressIndicator from "./ModuleProgressIndicator";

type Props = {
  module: Module;
  moduleProgress: ModuleProgress | undefined;
  moduleProgressLoading: boolean;
};

const ModuleCard = ({
  module,
  moduleProgress,
  moduleProgressLoading,
}: Props) => {
  const bg = useColorModeValue("white", "gray.800");

  // const { data: moduleProgress, loading: moduleProgressLoading } =
  //   useModuleProgress(module.index.toString());

  return (
    <Box
      bg={bg}
      rounded="xl"
      boxShadow="lg"
      as={Link}
      to={`/${module.slug}`}
      overflow="hidden"
      maxHeight={"350px"}
    >
      <Flex h="full" alignItems="top" flexDirection="column">
        <Box flex={4} h="full" overflow="hidden">
          <Image
            objectFit="cover"
            srcSet={module.image.images.fallback.srcSet}
            alt={module.title}
            h="full"
            w="full"
          />
        </Box>
        <Box
          flex={4}
          p={8}
          justifyContent="space-between"
          display="flex"
          flexDirection="column"
        >
          <Heading size="md">{module.title}</Heading>
          <Text mt="2" opacity={0.8}>
            Estimated time to finish:{" "}
            {module.topics
              .map((t) => t.time)
              .reduce((prev, curr) => prev + curr)}{" "}
            minutes
          </Text>
          <Box mt="2" width="fit-content">
            <ModuleProgressIndicator
              moduleProgress={moduleProgress}
              isLoading={moduleProgressLoading}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default ModuleCard;
