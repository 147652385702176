import { Auth } from "aws-amplify";
import { BASE_API_URL, useMutation, useQuery } from "./util";

export type GoalStatus = "NOT_STARTED" | "STARTED" | "COMPLETE";

export type CreateGoalDTO = {
  title: string;
  description: string;
  status: GoalStatus;
  relatedItems: string[];
};

export type Goal = {
  id: string;
  user: string;
  itemId: string;
  title: string;
  description: string;
  relatedItems: string[];
  status: GoalStatus;
};

export const useGoals = () => {
  return useQuery<Goal[]>("goals", async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/goal/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch goals");
    }

    const data = await response.json();

    return data?.Items ?? [];
  });
};

export const useCreateGoal = () => {
  return useMutation(async (dto: CreateGoalDTO) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/goal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dto),
    });

    if (!response.ok) {
      throw new Error("Failed to create goal");
    }

    return response.json();
  });
};

export const useUpdateGoal = () => {
  return useMutation(async (goalId: string, dto: CreateGoalDTO) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/goal/${goalId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(dto),
    });

    if (!response.ok) {
      throw new Error("Failed to update goal");
    }

    return response.json();
  });
};

export const useDeleteGoal = () => {
  return useMutation(async (goalId: string) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/goal/${goalId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete goal");
    }

    return response.json();
  });
};
