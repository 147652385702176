import {
  Box,
  Flex,
  Heading,
  Icon,
  Tag,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { FaChevronRight } from "react-icons/fa";
import { Goal } from "../../data/goalsApi";

type Props = {
  goal: Goal;
  onClick?: () => void;
};

const GoalCard = ({ goal, onClick }: Props) => {
  const statusColour = useMemo(() => {
    switch (goal.status) {
      case "STARTED":
        return "orange";
      case "COMPLETE":
        return "green";
      default:
        return "purple";
    }
  }, [goal]);

  const statusText = useMemo(() => {
    switch (goal.status) {
      case "STARTED":
        return "In Progress";
      case "COMPLETE":
        return "Complete";
      default:
        return "Not Started";
    }
  }, [goal]);

  const bg = useColorModeValue("blue.100", "gray.800");

  return (
    <Flex
      onClick={onClick}
      cursor="pointer"
      alignItems="center"
      justify="space-between"
      p={4}
      rounded="xl"
      border="1px solid"
      borderColor={useColorModeValue("gray.200", "gray.600")}
    >
      <Box>
        <Heading size="xs" mb={3} noOfLines={2} wordBreak="break-all">
          {goal.title}
        </Heading>
        <Tag colorScheme={statusColour}>{statusText}</Tag>
      </Box>
      <Icon as={FaChevronRight} opacity={0.5} />
    </Flex>
  );
};

export default GoalCard;
