import React from "react";
import { useAllModuleProgress, useModules } from "../data/modulesAPI";
import { SimpleGrid } from "@chakra-ui/react";
import ModuleCard from "./ModuleCard";

type Props = {};

const ModulesGrid = (props: Props) => {
  const modules = useModules();
  const { data: allModulesProgress, loading } = useAllModuleProgress();

  return (
    <SimpleGrid
      gap={8}
      templateColumns={[
        "1fr",
        "1fr",
        "repeat(2, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
    >
      {modules.map((module, index) => (
        <ModuleCard
          key={"Module" + index}
          module={module}
          moduleProgressLoading={loading}
          moduleProgress={allModulesProgress?.find((x) => {
            return x.id === `MODULE-PROGRESS-${module.index}`;
          })}
        />
      ))}
    </SimpleGrid>
  );
};

export default ModulesGrid;
