import {
  Box,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Spacer,
  useMediaQuery,
  Text,
  Link,
} from "@chakra-ui/react";
import Layout from "../../components/Layout";
import { useAllModuleProgress, useModules } from "../../data/modulesAPI";
import React, { useEffect, useState } from "react";
import ModuleCard from "../../components/ModuleCard";
import SearchComponent from "../../components/SearchComponent";
import UpNextItem from "../../components/dashboard/UpNextItem";
import Card from "../../components/Card";
import GoalsTile from "../../components/dashboard/GoalsTile";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import ModulesGrid from "../../components/ModulesGrid";

const Modules = () => {
  const [isMobile] = useMediaQuery("(max-width: 540px)");

  let completed = false;

  useEffect(() => {
    document.title = "Home";
  }, []);

  return (
    <main aria-label="Home">
      <Layout>
        <Flex flexDir="column">
          <Flex p={isMobile ? 3 : 0}>
            <Heading as="h1" mb={3}>
              Home
            </Heading>
            <Spacer />
            {
              isMobile ? <SearchComponent asInput={false} dark={false} /> : null
              // <AddLink refetch={refetch} />
            }
          </Flex>

          <Flex
            mb={4}
            gap={6}
            flexDir={isMobile ? "column" : "row"}
            alignItems="top"
            flexGrow={0}
          >
            <Card shadow="lg" flex={1} p={7}>
              <UpNextItem />
            </Card>
            <Box flex={1} w="full">
              <GoalsTile />
            </Box>
          </Flex>

          <Heading size="lg" mb={6}>
            Modules
          </Heading>
          <Spacer />
          <ModulesGrid />
          {isMobile ? <Box h="7em"></Box> : null}
        </Flex>

        <Text pt={5} pb={5} textAlign="center">
          <Link size="xs" color="blue.800" href="http://www.freepik.com/">
            Site graphics designed by FreePik
          </Link>
        </Text>
      </Layout>
    </main>
  );
};

export default Modules;
