import { Spinner, Tag } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { ModuleProgress } from "../data/modulesAPI";

type Props = {
  moduleProgress: ModuleProgress | undefined; //TODO: type this
  isLoading: boolean;
  fontSize?: string;
};

const ModuleProgressIndicator = ({
  moduleProgress,
  isLoading,
  fontSize,
}: Props) => {
  const progress = useMemo(() => {
    if (moduleProgress?.completed && moduleProgress?.total) {
      const percentage = moduleProgress.completed / moduleProgress.total;
      if (percentage === 0) {
        return "Not Started";
      } else if (percentage < 0.3) {
        return "Just Started";
      } else if (percentage < 0.5) {
        return "Almost Half Way";
      } else if (percentage < 0.6) {
        return "Half Way";
      } else if (percentage < 0.8) {
        return "Almost Done";
      } else {
        return "Finished";
      }
    } else if (moduleProgress) {
      return "Not Started";
    }
  }, [moduleProgress]);

  const progressColour = useMemo(() => {
    if (progress === "Not Started") {
      return "gray";
    } else if (progress === "Just Started") {
      return "yellow";
    } else if (progress === "Almost Half Way") {
      return "yellow";
    } else if (progress === "Half Way") {
      return "orange";
    } else if (progress === "Almost Done") {
      return "pink";
    } else if (progress === "Finished") {
      return "green";
    }
    return undefined;
  }, [progress]);

  return !progress && isLoading ? (
    <Spinner />
  ) : (
    <Tag fontSize={fontSize} colorScheme={progressColour}>
      {progress}
    </Tag>
  );
};

export default ModuleProgressIndicator;
